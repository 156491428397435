@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--swiper-navigation-color: #825ebb;
}

body {
	@apply bg-pattern bg-contain bg-scroll z-0;
}

@layer base {
	html {
		font-family: 'Montserrat', 'Roboto_Mono';
	}
	h1 {
		@apply uppercase text-2xl md:text-3xl text-primary text-center py-5;
	}
}

@layer components {
	.header-text {
		@apply text-slate-600 text-sm lg:text-lg font-mono;
	}

	.menu-container {
		@apply px-3 py-5 sticky top-0;
	}

	.header-sticky {
		@apply bg-light z-50;
	}

	.menu-btn {
		@apply z-50 col-span-1 bg-white h-16 rounded-md border-2 border-primary text-primary text-center text-[10px] md:text-xs xl:text-sm px-2 font-semibold uppercase;
	}

	.menu-btn.isActive {
		@apply bg-secondary;
	}

	.product-container {
		@apply px-5 lg:px-20 xl:px-40 2xl:px-72 py-5 sm:py-20 grid gap-y-5 sm:gap-y-40;
	}

	.footer {
		@apply flex justify-center pb-5;
	}

	.map-responsive {
		@apply overflow-hidden pb-[42.56%] relative h-0;
	}

	.map-responsive iframe {
		@apply left-0 top-0 border-0 h-[100%] w-[100%] absolute;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-button-prev,
	.swiper-button-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		font-size: 18px;
		font-weight: bold;
		padding: 8px 16px;
		border-radius: 4px;
		z-index: 10;
		/* border: 2px solid #333; */
		/* background-color: #fff; */
	}

	.swiper-button-prev {
		left: 20px;
	}

	.swiper-button-next {
		right: 20px;
	}
}
